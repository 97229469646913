import React, { useEffect, useState } from 'react';

import { useFirebase } from './App';

export function useFirestore() {
    const [firestore, setFirestore] = useState(null);

    const {
        firebaseApp: app,
        isInitialized,
        devInitialized,
        setDevInitialized,
    } = useFirebase();

    // Import the Firestore service upon usage

    useEffect(() => {
        if (!isInitialized) {
            console.warn('Firebase app is not initialized yet');
        } else {
            async function initFirestore() {
                const { getFirestore, connectFirestoreEmulator } = await import(
                    'firebase/firestore'
                );

                const db = getFirestore(app);
                if (!devInitialized) {
                    if (process.env.NODE_ENV === 'development') {
                        connectFirestoreEmulator(db, 'localhost', 8080);
                    }
                    setDevInitialized(true);
                }

                setFirestore(db);
            }

            if (app) {
                initFirestore();
            }
        }
    }, [app]);

    return firestore;
}

export function useAuth() {
    const { firebaseApp: app, isInitialized } = useFirebase();
    const [auth, setAuth] = useState(null);

    // Import the Firestore service upon usage

    useEffect(() => {
        if (!isInitialized) {
            console.warn('Firebase app is not initialized yet');
        } else {
            console.info('Firebase initialized');
            async function initAuth() {
                const { getAuth } = await import('firebase/auth');
                setAuth(getAuth(app));
            }

            if (app) {
                initAuth();
            }
        }
    }, [app]);

    return auth;
}

export function useFunctions() {
    const { firebaseApp: app, isInitialized } = useFirebase();
    const [functions, setFunctions] = useState(null);

    // Import the Firestore service upon usage

    useEffect(() => {
        if (!isInitialized) {
            console.warn('Firebase app is not initialized yet');
        } else {
            async function initFunctions() {
                const { getFunctions, connectFunctionsEmulator } = await import(
                    'firebase/functions'
                );
                setFunctions(getFunctions(app));
                if (process.env.NODE_ENV === 'development') {
                    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
                }
            }

            if (app) {
                initFunctions();
            }
        }
    }, [app]);

    return functions;
}
