import React, {
    useContext,
    useEffect,
    useState,
    Suspense,
    createContext,
} from 'react';

import { MenuProvider, MenuContext } from './hooks/MenuContext';
import { OverlayProvider, OverlayContext } from './hooks/OverlayContext';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { CartProvider } from './hooks/CartContext';
import { DataProvider } from './dataProvider';
import Header from './components/Header';
import Footer from './components/Footer';
import FontLoader from './components/FontLoader';
import Loader from './components/Loader';
import { MessageProvider } from './hooks/MessageContext';
import { RouteParamProvider, useRouteParam } from './hooks/RouteParamContext';
import { PromoCodeProvider } from './hooks/PromoCodeContext'; // adjust the path as necessary
import MessageBar from './components/MessageBar';
import useResizeObserver from './hooks/useResizeObserver';
//import CheckoutSuccess from './pages/CheckoutSuccess';
import './styles/main.scss'; // Import the SCSS file
const FirebaseContext = createContext(null);

function FirebaseProvider({ children }) {
    const [firebaseApp, setFirebaseApp] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [devInitialized, setDevInitialized] = useState(false);
    useEffect(() => {
        async function loadFirebase() {
            try {
                const firebaseModule = await import('firebase/app');
                const firebaseConfig = {
                    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                    storageBucket:
                        process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                    messagingSenderId:
                        process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                    appId: process.env.REACT_APP_FIREBASE_APP_ID,
                };
                const app = firebaseModule.initializeApp(firebaseConfig);
                const { getFunctions, connectFunctionsEmulator } = await import(
                    'firebase/functions'
                );
                const functions = getFunctions(app);
                if (process.env.NODE_ENV === 'development') {
                    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
                }
                setFirebaseApp(app);
                setIsInitialized(true);
            } catch (error) {
                console.error('Error initializing Firebase:', error);
                setIsInitialized(false); // Ensure initialization state is set even on failure
            }
        }
        const delayFunctions = window.requestIdleCallback || window.setTimeout;
        delayFunctions(() => loadFirebase());

        return () => {
            if (firebaseApp) {
                firebaseApp
                    .delete()
                    .catch((error) =>
                        console.error('Error deleting Firebase app:', error)
                    );
            }
        };
    }, []);

    return (
        <FirebaseContext.Provider
            value={{
                firebaseApp,
                isInitialized,
                devInitialized,
                setDevInitialized,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
}
export function useFirebase() {
    const { firebaseApp, isInitialized, devInitialized, setDevInitialized } =
        useContext(FirebaseContext);
    return { firebaseApp, isInitialized, devInitialized, setDevInitialized };
}

const Home = React.lazy(() => import('./pages/Home'));
const SearchPage = React.lazy(() => import('./pages/Search'));
const CollectionsPage = React.lazy(() => import('./pages/Collections'));
const BrandsPage = React.lazy(() => import('./pages/Brands'));
const BrandsListPage = React.lazy(() => import('./pages/BrandsList'));
const ProductPage = React.lazy(() => import('./pages/Product'));
const Policy = React.lazy(() => import('./pages/Policy'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const CartPage = React.lazy(() => import('./pages/Cart'));
const CheckoutPage = React.lazy(() => import('./pages/Checkout'));
const LoveNotesPage = React.lazy(() => import('./pages/LoveNotes'));
const InfoForm = React.lazy(() => import('./components/checkout/InfoForm'));
const CheckoutForm = React.lazy(() =>
    import('./components/checkout/CheckoutForm')
);
const ShippingOptions = React.lazy(() =>
    import('./components/checkout/ShippingOptions')
);
const CheckoutSuccess = React.lazy(() =>
    import('./components/checkout/CheckoutSuccess')
);
function App() {
    return (
        <FirebaseProvider>
            <OverlayProvider>
                <MenuProvider>
                    <DataProvider
                        collections={[
                            'vendors',
                            'categories',
                            'departments',
                            'sizeCollection',
                        ]}
                    >
                        <RouteParamProvider>
                            <AppContent />
                        </RouteParamProvider>
                    </DataProvider>
                </MenuProvider>
            </OverlayProvider>
        </FirebaseProvider>
    );
}

function AppContent() {
    const { isOverlayActive, activateOverlay } = useContext(OverlayContext);
    const { isMenuActive, toggleMenu } = useContext(MenuContext);
    const location = useLocation();
    const [ref, dimensions] = useResizeObserver();
    const { setParams } = useRouteParam(); // Use the new hook

    useEffect(() => {
        // This could be more dynamic based on your routing needs
        const match = /\/search\/([^\/]+)|\/collections\/([^\/]+)/.exec(
            location.pathname
        );
        const collection = match ? match[1] || match[2] : undefined;

        setParams({ collection }); // Update the context
    }, [location, setParams]);

    useEffect(() => {
        if (isOverlayActive) {
            document.body.classList.add('overlay-active');
        } else {
            document.body.classList.remove('overlay-active');
            if (isMenuActive) {
                toggleMenu();
            }
        }
    }, [isOverlayActive, isMenuActive, toggleMenu]);

    useEffect(() => {
        if (isMenuActive) {
            document.body.classList.add('menu-active');
        } else {
            document.body.classList.remove('menu-active');
        }
    }, [isMenuActive]);

    const isCheckoutRoute = location.pathname.startsWith('/checkout');
    const deactivateOverlay = () => {
        activateOverlay(false);
    };

    return (
        <div className="app">
            <FontLoader />
            <MessageProvider>
                <CartProvider>
                    <PromoCodeProvider>
                        {!isCheckoutRoute ? (
                            <Header contentHeight={dimensions.height} />
                        ) : (
                            <MessageBar />
                        )}
                        <div className="container" ref={ref}>
                            <div
                                className="overlay"
                                onClick={deactivateOverlay}
                            ></div>
                            <article className="main-content">
                                <Suspense
                                    fallback={
                                        <div className="page-loader page-container">
                                            <Loader />
                                        </div>
                                    }
                                >
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route
                                            path="search"
                                            element={<SearchPage />}
                                        />
                                        <Route
                                            path="search/:collection"
                                            element={<SearchPage />}
                                        />
                                        <Route
                                            path="/brands"
                                            element={<BrandsListPage />}
                                        />
                                        <Route
                                            path="/brands/:vendorSlug"
                                            element={<BrandsPage />}
                                        />
                                        <Route
                                            path="/collections/:collection"
                                            element={<CollectionsPage />}
                                        />
                                        <Route
                                            path="/collections/:collection/:handle"
                                            element={<ProductPage />}
                                        />
                                        <Route
                                            path="/cart"
                                            element={<CartPage />}
                                        />
                                        <Route
                                            path="/about-us/customer-feedback"
                                            element={<LoveNotesPage />}
                                        />
                                        <Route
                                            path="checkout/complete"
                                            element={<CheckoutSuccess />}
                                        />
                                        <Route
                                            path="checkout"
                                            element={<CheckoutPage />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate
                                                        to="info"
                                                        replace
                                                    />
                                                }
                                            />
                                            <Route
                                                path="info"
                                                element={<InfoForm />}
                                            />
                                            <Route
                                                path="shipping"
                                                element={<ShippingOptions />}
                                            />
                                            <Route
                                                path="payment"
                                                element={<CheckoutForm />}
                                            />
                                        </Route>
                                        <Route
                                            path="/policies/:page"
                                            element={<Policy />}
                                        />
                                        <Route
                                            path="/about-us/:page"
                                            element={<AboutUs />}
                                        />
                                    </Routes>
                                </Suspense>
                            </article>
                        </div>
                        <Footer />
                    </PromoCodeProvider>
                </CartProvider>
            </MessageProvider>
        </div>
    );
}

export default App;
