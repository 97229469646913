import React, { createContext, useCallback, useState } from 'react';

export const OverlayContext = createContext();

export function OverlayProvider({ children }) {
    const [isOverlayActive, setIsOverlayActive] = useState(false);
    const activateOverlay = useCallback((isActive) => setIsOverlayActive(isActive), [setIsOverlayActive]);

    const contextValue = {
        isOverlayActive,
        activateOverlay
    };

    return (
        <OverlayContext.Provider value={contextValue}>{children}</OverlayContext.Provider>
    );
}
