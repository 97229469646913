import React, { useState, useEffect, useRef } from 'react';

function WordFlicker(props) {
    const [part, setPart] = useState('');
    const forwards = useRef(true);
    const i = useRef(0);
    const offset = useRef(0);
    const skipCount = useRef(0);
    const lastTime = useRef(null);

    const words = props.words || [];
    const skipDelay = props.skipDelay || 15;
    const speed = props.speed || 70;

    const animate = (time) => {
        if (!lastTime.current) lastTime.current = time;

        if (time - lastTime.current > speed) {
            if (forwards.current) {
                if (offset.current >= words[i.current].length) {
                    skipCount.current += 1;
                    if (skipCount.current === skipDelay) {
                        forwards.current = false;
                        skipCount.current = 0;
                    }
                }
            } else {
                if (offset.current === 0) {
                    forwards.current = true;
                    i.current = (i.current + 1) % words.length;
                    offset.current = 0;
                }
            }
            setPart(words[i.current].substr(0, offset.current));
            if (skipCount.current === 0) {
                offset.current = forwards.current
                    ? offset.current + 1
                    : offset.current < 2
                    ? 0
                    : offset.current / 2;
            }
            lastTime.current = time;
        }

        requestAnimationFrame(animate);
    };

    useEffect(() => {
        const animationId = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationId);
    }, []);

    return <span className="word">{part}</span>;
}

export default WordFlicker;
