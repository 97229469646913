import React, { createContext, useContext, useState } from 'react';

const RouteParamContext = createContext();

export const useRouteParam = () => useContext(RouteParamContext);

export const RouteParamProvider = ({ children }) => {
    const [params, setParams] = useState({});

    return (
        <RouteParamContext.Provider value={{ params, setParams }}>
            {children}
        </RouteParamContext.Provider>
    );
};
