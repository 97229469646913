import React, { createContext, useContext, useState } from 'react';
import { OverlayContext } from './OverlayContext';

// Create the MenuContext
export const MenuContext = createContext();

// Create a MenuContext provider component
export function MenuProvider({ children }) {
    const { activateOverlay } = useContext(OverlayContext);
    const [isMenuActive, setIsMenuActive] = useState(false);

    const toggleMenu = () => {
        setIsMenuActive((prev) => !prev);
        activateOverlay(!isMenuActive);
    };

    // Provide the necessary values through the context
    const contextValue = {
        isMenuActive,
        toggleMenu,
    };

    return (
        <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
    );
}
