import React, { useEffect, useRef, useContext } from 'react';
import { ForwardIcon } from './icons';
import './SearchResults.scss';
import { DataContext } from '../dataProvider';
const imageCDN = process.env.REACT_APP_IMAGE_CDN;

const SearchResults = ({
    results,
    query,
    hide,
    showMaxResults,
    focusIndex,
    searchPath,
}) => {
    const resDom = useRef(null);
    const { filteredProducts } = useContext(DataContext);

    useEffect(() => {
        const items = resDom.current.querySelectorAll('.search-item');
        if (items.length > 0 && focusIndex >= 0) {
            items[focusIndex].focus();
        }
    }, [focusIndex, results]);
    useEffect(() => {
        if (results.length > 0 && !hide) {
            resDom.current.classList.add('showResults');
        } else {
            resDom.current.classList.remove('showResults');
        }
    }, [results, hide]);
    return (
        <div className="search-results" ref={resDom}>
            <h2>Available Clothing</h2>
            {results
                .slice(0, showMaxResults)
                .map(
                    ({ id, name, price, departmentName, thumbnail }, index) => (
                        <a
                            key={id}
                            className="search-item"
                            href={`/collections/${departmentName.toLowerCase()}/${id}`}
                            tabIndex={-1} // Make it focusable via script but not via tab key
                        >
                            <img
                                src={`${imageCDN}/w_165/${thumbnail}.webp`}
                                alt={name}
                            />
                            <h3> {name}</h3>{' '}
                        </a>
                    )
                )}
            <div className="view-more">
                <a
                    className="button search-item"
                    href={searchPath()}
                    tabIndex={-1}
                >
                    <div>
                        Show <span className="chip">{results.length}</span>{' '}
                        results for: <em>{query}</em>
                    </div>
                    <ForwardIcon />
                </a>
            </div>
        </div>
    );
};

export default SearchResults;
