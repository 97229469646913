import React, { useContext, useRef } from 'react';
import { MessageContext } from '../hooks/MessageContext';
import WordFlicker from './WordFlicker';

import './MessageBar.scss';
import { CloseIcon, DeadFace } from './icons';

const MessageBar = () => {
    const { messages, removeMessage } = useContext(MessageContext);
    const messageRefs = useRef({});
    const handleCloseMessage = (id) => {
        const messageEl = messageRefs.current[id];
        if (messageEl) {
            messageEl.classList.add('closing');

            setTimeout(() => {
                removeMessage(id);
                delete messageRefs.current[id];
            }, 300); // After 300ms (animation duration), remove message.
        }
    };
    return (
        <div className={`message-bar ${messages.length > 0 && `active`}`}>
            {messages
                .filter(({ type }) => type === 'cart-update')
                .map((msg, i) => (
                    <div
                        key={i}
                        ref={(el) => (messageRefs.current[msg.id] = el)}
                        className={`message message-${msg.type}`}
                    >
                        {msg.content}

                        {msg.muteable && (
                            <button onClick={() => handleCloseMessage(msg.id)}>
                                <CloseIcon />
                            </button>
                        )}
                    </div>
                ))}

            {messages
                .filter(({ type }) => type === 'promo')
                .map((msg, i) => (
                    <div
                        key={i}
                        className={`message message-${msg.type}`}
                        ref={(el) => (messageRefs.current[msg.id] = el)}
                    >
                        <WordFlicker
                            words={msg.content}
                            skipDelay={20}
                            speed={100}
                        />
                        {msg.muteable && (
                            <button onClick={() => handleCloseMessage(msg.id)}>
                                <CloseIcon />
                            </button>
                        )}
                    </div>
                ))}
            {messages
                .filter(({ type }) => type === 'error')
                .map((msg, i) => (
                    <div
                        key={i}
                        ref={(el) => (messageRefs.current[msg.id] = el)}
                        className={`message message-${msg.type}`}
                    >
                        <DeadFace /> {msg.content}
                        {/* {msg.muteable && (
                            <button onClick={() => removeMessage(msg.id)}>
                                <CloseIcon />
                            </button>
                        )} */}
                    </div>
                ))}
        </div>
    );
};
export default MessageBar;
