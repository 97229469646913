import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Footer.scss'; // Link to your SCSS or CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-content">
                    <section className="footer-section">
                        <h4>Quick Links</h4>
                        <ul className="footer-links">
                            <li>
                                <Link to="/about-us/our-story">Our Story</Link>
                            </li>
                            <li>
                                <Link
                                    title="Love Notes"
                                    to="/about/customer-feedback"
                                >
                                    Love Notes
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us/contact">Contact</Link>
                            </li>

                            <li>
                                <Link
                                    title="Frequently Asked Questions"
                                    to="/about-us/faq"
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us/land-acknowledgement">
                                    Land Acknowledgement
                                </Link>
                            </li>
                        </ul>
                    </section>
                    <section className="footer-section">
                        <h4>Policy Links</h4>
                        <ul className="footer-links">
                            <li>
                                <Link to="/policies/terms-and-conditions">
                                    Terms of Service
                                </Link>
                            </li>
                            <li>
                                <Link to="/policies/privacy">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/policies/returns-and-refunds">
                                    Return & Refunds
                                </Link>
                            </li>
                            <li>
                                <Link to="/policies/shipping">Shipping</Link>
                            </li>
                        </ul>
                    </section>
                    <section className="footer-section">
                        <h4>Location</h4>
                        <p>10999 Hwy 217</p>
                        <p>Roxville, Nova Scotia</p>
                        <p>B0V 1A0</p>
                    </section>
                    <section className="footer-section">
                        <h4>Follow Us</h4>
                        <div className="social-icons">
                            <ul className="footer-links social-icons">
                                <li>
                                    <a
                                        href="https://poshmark.ca/closet/thrfts"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/pm.png"
                                            height={21}
                                            width={18}
                                            alt="Poshmark"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/fb.png"
                                            height={24}
                                            width={24}
                                            alt="Facebook"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/thrfts.ca"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/ig.png"
                                            height={24}
                                            width={24}
                                            alt="Instagram"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/angela-macalpine-45a50623/">
                                        <img
                                            src="/images/li.png"
                                            height={24}
                                            width={24}
                                            alt="LinkedIn"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCECNCB7v_F2yZIPCDBoMyUw"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/yt.png"
                                            height={24}
                                            width={24}
                                            alt="YouTube"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <div className="footer-bottom">
                        <p>
                            © 2024 THRFTS. <br />
                            All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
