import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from 'react';
import {
    collection,
    doc,
    setDoc,
    updateDoc,
    query,
    where,
    deleteDoc,
    onSnapshot,
} from 'firebase/firestore';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { formatCurrency } from '../utils';
import { useAuth, useFirestore } from '../firebase';
import { MessageContext } from './MessageContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [cart, setCart] = useState([]);
    const [loadingCart, setLoadingCart] = useState(true);
    const [user, setUser] = useState(null);
    const [showSuccess, setShowSuccess] = useState(null);
    const [cartDisplaySubTotal, setCartDisplaySubTotal] = useState('');
    const [cartSubTotal, setCartSubTotal] = useState('');
    const [isCheckingOut, setIsCheckingOut] = useState();
    const [soldCartItems, setSoldCartItems] = useState();
    const [clientSecret, setClientSecret] = useState();
    const [adjustments, setAdjustments] = useState({
        totalDiscount: 0,
        discount: [],
        shipping: [],
    });

    const { addMessage, removeMessage } = useContext(MessageContext);

    const auth = useAuth();
    const db = useFirestore();
    useEffect(() => {
        if (soldCartItems && soldCartItems.length > 0) {
            // Ensures the array is not empty
            const updatePromises = soldCartItems.map(({ cartItemId }) => {
                const itemRef = doc(db, 'cartItems', cartItemId); // Reference to the document
                return updateDoc(itemRef, { status: 'removed' }) // Firestore update operation
                    .catch((error) => {
                        // Error handling for each individual update
                        console.error(
                            `Error updating item ${cartItemId}:`,
                            error
                        );
                        return null; // Return null or appropriate error handling
                    });
            });

            Promise.all(updatePromises)
                .then(() => {
                    console.log('All items updated successfully');
                })
                .catch((error) => {
                    console.error(
                        'An error occurred during bulk updates:',
                        error
                    );
                });
            soldCartItems.forEach(({ name }) => {
                addMessage('cart-update', [
                    `🙄 Doh! ${name} has just been sold and removed from your cart.`,
                    'cart-update',
                    { mutable: true, global: true },
                ]);
            });
        }
    }, [soldCartItems]);
    useEffect(() => {
        setIsCheckingOut(
            location.pathname.split('/')[1] === 'checkout' &&
                location.pathname.split('/')[2] !== 'complete'
        );
    }, [location]);
    useEffect(() => {
        if (!auth || !db) return;

        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const itemsRef = collection(db, 'cartItems');
                const q = query(
                    itemsRef,
                    where('userId', '==', currentUser.uid),
                    where('status', 'in', ['in-cart', 'sold-out'])
                );
                return onSnapshot(q, (snapshot) => {
                    const items = snapshot.docs
                        .filter((doc) => doc.data().status === 'in-cart')
                        .map((doc) => ({
                            cartItemId: doc.id,
                            ...doc.data(),
                        }));
                    if (!items.length && isCheckingOut) {
                        navigate('/cart');
                    }
                    setCart(items);

                    const soldOutItems = snapshot.docs
                        .filter((doc) => doc.data().status === 'sold-out')
                        .map((doc) => ({
                            cartItemId: doc.id,
                            ...doc.data(),
                        }));
                    if (soldOutItems) {
                        setSoldCartItems(soldOutItems);
                    }

                    setLoadingCart(false);
                });
            } else {
                signInAnonymously(auth)
                    .then(({ user }) => setUser(user))
                    .catch((error) =>
                        console.error('Anonymous sign-in error:', error)
                    );
            }
        });

        return unsubscribeAuth;
    }, [auth, db]);

    useEffect(() => {
        if (!user || !db) return;

        const docRef = doc(db, 'carts', user.uid);
        const adjustmentsRef = collection(docRef, 'adjustments');
        const unsubscribe = onSnapshot(adjustmentsRef, (snapshot) => {
            const adjustments = snapshot.docs.map((doc) => {
                let { shipping, discount, totalDiscount } = doc.data().promos;

                // discount = discount.map((obj) =>
                //     Object.assign(obj, {
                //         displayValue: formatCurrency(obj.value / 100),
                //     })
                // );
                // shipping = shipping.map((obj) =>
                //     Object.assign(obj, {
                //         displayShippingCost: formatCurrency(
                //             obj.displayShippingCost / 100
                //         ),
                //     })
                // );
                return {
                    shipping,
                    discount,
                    totalDiscount,
                };
            });
            if (adjustments[0]) setAdjustments(adjustments[0]);
        });
        return unsubscribe;
    }, [user, db]);

    const addToCart = useCallback(
        async (item) => {
            if (!user) return;

            const itemExists = cart.some((cartItem) => cartItem.id === item.id);
            if (itemExists) {
                setShowSuccess({
                    status: 'error',
                    message: 'Item already in cart',
                });
                return;
            }

            const cartItemRef = doc(collection(db, `cartItems`));
            await setDoc(cartItemRef, {
                ...item,
                userId: user.uid,
                status: 'in-cart',
            });
            setShowSuccess({
                status: 'success',
                message: 'Item added to cart',
                item,
            });
        },
        [user, cart, db]
    );

    const removeFromCart = useCallback(
        async (cartItemId) => {
            if (!user) return;

            const cartItemRef = doc(collection(db, `cartItems`), cartItemId);
            await deleteDoc(cartItemRef);
            setCart(cart.filter((item) => item.cartItemId !== cartItemId));
        },
        [user, cart, db]
    );

    useEffect(() => {
        let subTotal = cart.reduce((total, item) => total + item.salePrice, 0);
        // console.info({ subTotal, adjustments });
        setCartSubTotal(subTotal);
        setCartDisplaySubTotal(formatCurrency(subTotal / 100));
    }, [cart]);

    const contextValue = useMemo(
        () => ({
            cart,
            user,
            showSuccess,
            cartSubTotal,
            cartDisplaySubTotal,
            clientSecret,
            loadingCart,
            adjustments,
            setClientSecret,
            addToCart,
            removeFromCart,
            setShowSuccess,
            isItemInCart: (itemId) => cart.some((item) => item.id === itemId),
        }),
        [
            cart,
            user,
            showSuccess,
            cartSubTotal,
            cartDisplaySubTotal,
            clientSecret,
            loadingCart,
            adjustments,
            setClientSecret,
            addToCart,
            removeFromCart,
        ]
    );

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
