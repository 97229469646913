import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import SearchResults from './SearchResults';
import { OverlayContext } from '../hooks/OverlayContext';
import { CloseIcon, SearchIcon } from './icons';
import { DataContext } from '../dataProvider';
import { useRouteParam } from '../hooks/RouteParamContext';
import './SearchBox.scss'; // Import the SCSS file
import { useLocation, useNavigate } from 'react-router-dom';
import { performSearch } from '../utils';
const SearchBox = ({ currentPageQuery }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [hideResults, setHideResults] = useState(false);
    const [hasText, setHasText] = useState(false);
    const { searchIndex, filteredProducts, setBaseFilteredProducts } =
        useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { params } = useRouteParam();
    const collection = params.collection;
    const searchInputRef = useRef(null);
    const { activateOverlay } = useContext(OverlayContext);
    const [focusIndex, setFocusIndex] = useState(-1);
    const searchContainerRef = useRef(null);
    const resetSearchBox = useCallback(() => {
        activateOverlay(false);
        setIsExpanded(false);
        setSearchQuery('');
        setSearchResults([]);
        searchInputRef.current.value = '';
        searchInputRef.current.blur();
    }, [activateOverlay]);
    const showMaxResults = 5;
    const toggleSearch = useCallback(
        (activate) => {
            if (isExpanded) {
                setSearchResults([]);
                setTimeout(() => {
                    resetSearchBox();
                }, 300);
            }
            if (!activate) {
                activateOverlay(false);
            }
            setIsExpanded(activate);
        },
        [activateOverlay, isExpanded, resetSearchBox]
    );

    useEffect(() => {
        if (isExpanded && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isExpanded]);

    useEffect(() => {
        if (currentPageQuery) {
            setSearchQuery(currentPageQuery);
            searchInputRef.current.value = currentPageQuery;
            setHasText(true);
            setHideResults(true);
        }
    }, [currentPageQuery]);

    const handleFocus = () => {
        setFocusIndex(-1);
        setHasText(true);
        setHideResults(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchContainerRef.current &&
                !searchContainerRef.current.contains(event.target)
            ) {
                setHideResults(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBlur = () => {
        // Delay hiding to catch clicks on results
        setTimeout(() => {
            console.info(focusIndex);
            if (
                focusIndex === -1 &&
                searchInputRef.current &&
                !searchInputRef.current.contains(document.activeElement)
            ) {
                setHideResults(true);
                if (!searchInputRef.current.value) {
                    setHasText(false);
                }
            }
        }, 200);
    };

    const liveSearch = useCallback(
        (query) => {
            const results = performSearch(query, searchIndex, filteredProducts);

            setSearchResults(results);
        },
        [searchIndex, filteredProducts]
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && searchQuery.length) {
            setBaseFilteredProducts([]);
            console.log('Executing final search for:', searchQuery);
            executeSearch();
        }
    };
    const executeSearch = () => {
        navigate(searchPath(), { replace: false });
        resetSearchBox();
    };
    const searchPath = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('q', searchQuery);
        queryParams.set('page', 1);
        return `/search${
            collection ? `/${collection}` : ``
        }?${queryParams.toString()}`;
    };
    const handleChange = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        if (!value.length) {
            setSearchResults([]); // Clear results if search query is cleared
        } else {
            liveSearch(value); // Trigger live search based on input
        }
    };
    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                setFocusIndex((prevIndex) => {
                    const maxIndex =
                        searchResults.length < showMaxResults
                            ? searchResults.length
                            : showMaxResults;

                    console.info(maxIndex, prevIndex);
                    let newIdex = prevIndex;
                    if (event.key === 'ArrowDown') {
                        newIdex = prevIndex < maxIndex ? prevIndex + 1 : 0;
                    } else if (prevIndex > -1) {
                        newIdex = prevIndex > -1 ? prevIndex - 1 : maxIndex;
                    }
                    if (newIdex === -1) {
                        searchInputRef.current.focus();
                    }
                    return newIdex;
                });
                event.preventDefault(); // Prevent scrolling the page with arrow keys
            } else if (event.key === 'Escape') {
                setFocusIndex(-1);
                searchInputRef.current.focus();
            }
        },
        [searchResults.length]
    );
    return (
        <div
            className={`search-box ${isExpanded ? 'expanded' : ''}`}
            onKeyDown={handleKeyDown}
            ref={searchContainerRef}
        >
            <SearchIcon onClick={() => toggleSearch(true)} />
            <div className="search-overlay">
                <div className="input-bar">
                    <label className="input-label">
                        <div
                            className={`label-text ${
                                hasText ? 'has-text' : ''
                            }`}
                        >
                            Search
                        </div>
                        <input
                            type="text"
                            className="search-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            ref={searchInputRef}
                            onKeyPress={handleKeyPress}
                            onFocus={handleFocus}
                        />
                        <CloseIcon onClick={() => toggleSearch(false)} />
                        <SearchIcon onClick={handleKeyPress} />
                    </label>
                </div>
                <SearchResults
                    hide={hideResults}
                    results={searchResults}
                    query={searchQuery}
                    focusIndex={focusIndex}
                    showMaxResults={showMaxResults}
                    searchPath={searchPath}
                />
            </div>
        </div>
    );
};

export default SearchBox;
