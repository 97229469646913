import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CartIcon } from '../components/icons';

export const MessageContext = React.createContext();
const errorMessages = {
    'already-in-cart': [
        <>
            This item is already in your <Link to="/cart">cart</Link>.
        </>,
        'error',
    ],
};
export const MessageProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const location = useLocation(); // Get the current location using the useLocation hook
    const { pathname } = location;
    useEffect(() => {
        // Clear error messages on location change
        setMessages((prevMessages) =>
            prevMessages.filter(
                (msg) => pathname === msg.pathname || msg.global
            )
        );
    }, [location.pathname]); // Re-run the effect whenever the location changes

    const addMessage = useCallback(
        (code, config) => {
            setMessages((prevMessages) => {
                const existingMessageIndex = prevMessages.findIndex(
                    (message) => message.code === code
                );

                if (existingMessageIndex !== -1) {
                    // If a message with the same code exists, update it
                    const updatedMessages = [...prevMessages];
                    const updatedMessage = {
                        ...updatedMessages[existingMessageIndex],
                        ...config,
                    };
                    updatedMessages[existingMessageIndex] = updatedMessage;
                    return updatedMessages;
                } else {
                    // If the message with the code doesn't exist, add it
                    const [content, type, params = {}] =
                        errorMessages[code] || config;
                    const { mutable, global, id = Date.now() } = params;

                    return [
                        ...prevMessages,
                        {
                            content,
                            id,
                            type,
                            pathname,
                            code, // Include the code in the message object
                            ...params,
                        },
                    ];
                }
            });
        },
        [setMessages]
    );
    const removeMessage = (id) => {
        setMessages((prev) => prev.filter((msg) => msg.id !== id));
    };

    return (
        <MessageContext.Provider
            value={{ messages, addMessage, removeMessage }}
        >
            {children}
        </MessageContext.Provider>
    );
};
