import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useCart } from '../hooks/CartContext';
import { CartIcon } from './icons';
import './Cart.scss';

const Cart = () => {
    const { cart, userEmail } = useCart();

    return (
        <div className="cart-container">
            <Link title={'cart'} className="cart-button" to={'/cart'}>
                <CartIcon />
                {cart.length ? (
                    <div className="cart-count">{cart.length}</div>
                ) : (
                    ''
                    // Check if the item is already in the cart
                )}
            </Link>
        </div>
    );
};
export default Cart;
