import React, { createContext, useState, useEffect, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirestore } from '../firebase';
import {
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
    arrayUnion,
    arrayRemove,
} from 'firebase/firestore';
import { useCart } from '../hooks/CartContext';
// Create the context
const PromoCodeContext = createContext();

// Create a provider component
export const PromoCodeProvider = ({ children }) => {
    const { user } = useCart();
    const db = useFirestore();
    const [promoCodes, setPromoCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const addPromoCode = async (code) => {
        // Check if the promo code ID is already in state to avoid duplicates
        setLoading(true);
        const normalizedCode = code.toLowerCase();
        const promoQuery = query(
            collection(db, 'promos'),
            where('code', '==', normalizedCode)
        );

        const promoDocs = await getDocs(promoQuery);

        if (!promoDocs.empty) {
            const promoDoc = promoDocs.docs[0];
            const promoDocId = promoDoc.id;
            if (!promoCodes.some((pc) => pc.id === promoDocId)) {
                const promo = { id: promoDocId, code: normalizedCode };
                const cartRef = doc(db, 'carts', user.uid);
                await updateDoc(cartRef, {
                    promoCodes: arrayUnion(promo),
                });
            }
        } else {
            console.log('Promo code is invalid or not found.');
        }
        setLoading(false);
    };

    const removePromoCode = async (codeId, cartId) => {
        const promoCodeToRemove = promoCodes.find((code) => code.id === codeId);

        setPromoCodes((currentCodes) =>
            currentCodes.filter(({ id }) => id !== codeId)
        );

        const cartRef = doc(db, 'carts', user.uid);
        await updateDoc(cartRef, {
            promoCodes: arrayRemove(promoCodeToRemove),
        });
    };

    return (
        <PromoCodeContext.Provider
            value={{
                loading,
                promoCodes,
                addPromoCode,
                removePromoCode,
                setPromoCodes,
            }}
        >
            {children}
        </PromoCodeContext.Provider>
    );
};

export const usePromoCode = () => useContext(PromoCodeContext);
