import React from 'react';
import LoaderGif from '../loaders/loader.gif';

function Loader({ loading = 1, children }) {
    if (loading) {
        return (
            <div className="loader-layover">
                <img className="loader" src={LoaderGif} alt="Loading..." />
            </div>
        );
    }

    return children;
}

export default Loader;
