import React from 'react';
import { Helmet } from 'react-helmet-async';

import '@fontsource/bebas-neue';
import '@fontsource-variable/montserrat';
const FontLoader = () => {
    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    href="../../public/fonts/bebas-neue-v14-latin-regular.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin="anonymous"
                />

                <link
                    rel="preload"
                    href="/static/media/montserrat-latin-wght-normal.b66f2d18f66f15c3ac56.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
            </Helmet>
        </>
    );
};

export default FontLoader;
