export function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}
export function formatPrice(amt, currency = 'CAD') {
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency,
    });
    const formattedValue = formatter.format(amt);
    return formattedValue;
}
export const formatCurrency = (value) => {
    const cleanedValue = value.toString().replace(/[^\d.-]/g, '');
    const numericValue = parseFloat(cleanedValue);
    if (isNaN(numericValue)) {
        return value;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
    });
    return formatter.format(numericValue);
};
// searchUtils.js
export const performSearch = (query, searchIndex, products) => {
    const trimmedQuery = query.toLowerCase().trim().replace(/\s+/g, ' ');
    const queryTerms = trimmedQuery.split(' ');
    console.info(query, searchIndex, products);
    const resultSet = new Set();
    const filteredByCollection = products.filter((product) => product);

    queryTerms.forEach((queryTerm) => {
        const singularQueryTerm = queryTerm.endsWith('s')
            ? queryTerm.slice(0, -1)
            : queryTerm;
        const pluralQueryTerm = queryTerm.endsWith('s')
            ? queryTerm
            : `${queryTerm}s`;

        Object.keys(searchIndex).forEach((indexTerm) => {
            if (
                indexTerm.startsWith(singularQueryTerm) ||
                indexTerm.startsWith(pluralQueryTerm)
            ) {
                searchIndex[indexTerm].forEach((id) => {
                    const product = filteredByCollection.find(
                        (product) => product.id === id
                    );
                    if (product) {
                        resultSet.add(product);
                    }
                });
            }
        });
    });
    console.info({ resultSet });
    return Array.from(resultSet);
};
