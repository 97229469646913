import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from 'firebase/firestore';
import { useFirestore } from './firebase';
export const DataContext = createContext();
const convertToKebab = (name) => {
    const handleValue = name
        .toLowerCase()
        .normalize('NFD') // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/&/g, '-and-') // Replace '&' with '-and-'
        .replace(/[/\.]/g, '-') //convert periods and fwd slash to dashes
        .replace(/[^a-z0-9\s-]/g, '') // Remove all non-alphanumeric characters, except spaces and dashes
        .replace(/\s+/g, '-') // Replace one or more spaces with a single hyphen
        .replace(/-+/g, '-') // Replace multiple consecutive hyphens with a single hyphen
        .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
    return handleValue;
};
const createIndex = (products) => {
    const index = {};
    products.forEach((product) => {
        const {
            id,
            name,
            description,
            categoryName,
            subCategoryName,
            tags,
            departmentName,
            color,
        } = product;

        const terms = [
            name,
            description,
            categoryName,
            subCategoryName,
            tags?.join(' '), // Assuming tags is an array
            departmentName,
            `${departmentName}s`,
            color,
        ]
            .join(' ')
            .toLowerCase()
            .replace(/[\u0300-\u036f]/g, '') // Remove diacritics, assuming this is still desired
            .split(/[/\s,-]+/) // Split on whitespace, hyphens, and ensure commas at the end of strings are removed before splitting
            .map((term) => term.replace(/,$/, '')) // Trim trailing commas from each term
            .filter((term) => term.length)
            .reduce((acc, term) => {
                // Skip terms that are just special characters
                if (!/^[^a-z0-9]+$/i.test(term)) {
                    acc.push(term);
                    // If term ends with 's, add three versions
                    if (term.endsWith("'s")) {
                        acc.push(term.slice(0, -2), term.replace(/'s$/, 's'));
                    }
                }
                return acc;
            }, []);

        terms.forEach((term) => {
            if (index[term]) {
                index[term].add(id);
            } else {
                index[term] = new Set([id]);
            }
        });
    });
    console.info('SearchIndex: ', Object.keys(index));
    return index;
};

export const DataProvider = ({ children, collections }) => {
    const db = useFirestore();
    const [data, setData] = useState({
        products: [],
    });
    const [departmentSlug, setDepartmentSlug] = useState([]);
    const [baseFilteredProducts, setBaseFilteredProducts] = useState([]);
    const [filtersInitialized, setFiltersInitialized] = useState(false);
    const [activeFilters, setActiveFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchIndex, setSearchIndex] = useState({});

    const navigate = useNavigate();
    const location = useLocation();
    const productFilters = [
        {
            title: 'Products',
            key: 'categoryId',
            queryKey: 'products',
            collection: 'categories',
        },
        {
            title: 'Brands',
            queryKey: 'brands',
            key: 'vendorId',
            collection: 'vendors',
        },
        {
            title: 'Size Range',
            queryKey: 'size-ranges',
            key: 'sizeCollection',
            collection: 'sizeCollection',
        },
    ];
    const getSlugById = useCallback(
        (id, dataType) => {
            const collectionData = data[dataType] || [];

            const found = collectionData.find((item) => item.id === id);

            return found ? found.slug : undefined;
        },
        [data]
    );
    const getIdBySlug = useCallback(
        (slug, dataType) => {
            const collectionData = data[dataType] || [];
            const found = collectionData.find((item) => item.slug === slug);
            console.info('getIdBySlug', {
                slug,
                dataType,
                collectionData,
                found,
            });

            return found ? found.id : undefined;
        },
        [data]
    );
    const getProductById = (itemId) => {
        return data.products
            ? data.products.some((item) => item.id === itemId)
            : {};
    };
    const getVendorBySlug = (slug) => {
        return data.vendors
            ? data.vendors.find((vendor) => vendor.slug === slug)
            : {};
    };
    const getVendorById = (id) => {
        return data.vendors
            ? data.vendors.find((vendor) => vendor.id === id)
            : {};
    };
    // Function to find department name from category ID
    const getCategoryDepartmentName = (categoryId) => {
        const category = data.categories.find((cat) => cat.id === categoryId);

        if (!category) {
            return null;
        }

        const department = data.departments.find(
            (dep) => dep.id === category.departmentId
        );

        if (!department) {
            return null;
        }

        return department.name;
    };

    const fetchProductsFromFirestore = (db) => {
        const productsCollectionRef = collection(db, 'items'); // 'items' as per Firestore collection name
        const q = query(
            productsCollectionRef,
            where('status', 'in', ['active', 'exported']),
            orderBy('createdate', 'desc') // Order by createdate in descending order
        );

        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                const products = snapshot.docs.map((doc) => {
                    const {
                        categoryId,
                        color,
                        condition,
                        departmentName,
                        departmentId,
                        description,
                        displaySalePrice,
                        labelSize,
                        measurements,
                        media,
                        name,
                        premium,
                        salePrice,
                        sizeCollection,
                        subcategoryName,
                        tags,
                        thumbnail,
                        vendor,
                        vendorId,
                    } = doc.data();
                    return {
                        id: doc.id,
                        categoryId,
                        color,
                        condition,
                        departmentName,
                        departmentId,
                        description,
                        displaySalePrice,
                        labelSize,
                        measurements,
                        media,
                        name,
                        premium,
                        salePrice,
                        sizeCollection,
                        subcategoryName,
                        tags,
                        thumbnail,
                        vendor,
                        vendorId,
                    };
                });
                setBaseFilteredProducts(products);
                setData((prevData) => ({
                    ...prevData,
                    products: products,
                }));

                console.info('Firestore products loaded');
                const index = createIndex(products);
                setSearchIndex(index);

                const now = Date.now();
                const cacheKey = 'data-products';
                const cacheTimestampKey = `${cacheKey}-timestamp`;
                console.log(`Updated cache for products`);
                localStorage.setItem(cacheKey, JSON.stringify(products));
                localStorage.setItem(cacheTimestampKey, String(now));
            },
            (error) => {
                console.error('Error fetching products from Firestore:', error);
                setError(error);
            }
        );

        // Return the unsubscribe function to stop listening for updates when the component unmounts
        return unsubscribe;
    };
    useEffect(() => {
        console.info('dataProvider');
        const getProductsFromApi = async () => {
            try {
                console.info('Retrieving API products');
                const cacheKey = 'data-products';
                const cacheTimestampKey = `${cacheKey}-timestamp`;
                const cachedData = localStorage.getItem('products');
                const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

                const now = Date.now();
                const cacheValidity = 3000;
                if (
                    cachedData &&
                    cachedTimestamp &&
                    now - Number(cachedTimestamp) < cacheValidity
                ) {
                    console.log(`Using cached data for products`);
                    setData((prevData) => ({
                        ...prevData,
                        products: cachedData,
                    }));
                } else if (data.products.length) {
                    fetchDataForCollection();
                } else {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/products`
                    );
                    if (!response.ok) {
                        throw new Error(
                            `Network response was not ok for collection products`
                        );
                    }
                    const jsonData = await response.json();
                    console.info(jsonData);
                    setData((prevData) => ({
                        ...prevData,
                        products: jsonData.results,
                    }));

                    setLoading(false);
                }
            } catch (error) {
                console.error(`Error fetching products mini list`, error);
                throw error; // It's important to re-throw the error so the calling code can handle it
            }
        };
        if (!data.products.length) getProductsFromApi();
    }, []);
    useEffect(() => {
        if (db) {
            const unsubscribeProducts = fetchProductsFromFirestore(db);

            // Call unsubscribe on the cleanup function to stop listening to changes
            return () => {
                unsubscribeProducts();
            };
        }
    }, [db]); // Empty dependencies array means this effect runs once on mount

    const fetchDataForCollection = useCallback(async (collectionName) => {
        const cacheKey = `data-${collectionName}`;
        const cacheTimestampKey = `${cacheKey}-timestamp`;
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

        const now = Date.now();
        const cacheValidity = 86400000;

        if (
            cachedData &&
            cachedTimestamp &&
            now - Number(cachedTimestamp) < cacheValidity
        ) {
            console.log(`Using cached data for ${collectionName}`);
            return JSON.parse(cachedData);
        } else {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/${collectionName}`
                );
                if (!response.ok) {
                    throw new Error(
                        `Network response was not ok for collection ${collectionName}`
                    );
                }
                const jsonData = await response.json();
                const slugCount = {}; // Object to keep track of how many times a slug has been used

                const dataWithSlugs = jsonData.results.map((item) => {
                    let slug = convertToKebab(item.name);
                    // Check if the slug already exists, and if so, append a unique identifier
                    if (slugCount[slug]) {
                        // If it's a duplicate, append an identifier (e.g., a counter or departmentId)
                        // Example uses a counter, but you might use departmentId or another field to ensure uniqueness
                        slug += `-${slugCount[slug]}`;
                        slugCount[slug] = 1; // Initialize the counter for the new, unique slug
                    } else {
                        slugCount[slug] = 1; // Initialize the counter for this slug
                    }

                    // Increment the count for this original slug (without suffix) to handle future duplicates
                    slugCount[convertToKebab(item.name)]++;

                    return {
                        ...item,
                        slug: slug,
                    };
                });

                // Cache the fetched data along with the current timestamp, but skip for 'products' as per your logic
                if (collectionName !== 'products') {
                    localStorage.setItem(
                        cacheKey,
                        JSON.stringify(dataWithSlugs)
                    );
                    localStorage.setItem(cacheTimestampKey, String(now));
                    console.log(`Updated cache for ${collectionName}`);
                }
                return dataWithSlugs;
            } catch (error) {
                console.error(
                    `Error fetching collection: ${collectionName}`,
                    error
                );
                throw error; // It's important to re-throw the error so the calling code can handle it
            }
        }
    }, []);

    const filteredProducts = useMemo(() => {
        if (Object.keys(activeFilters))
            return baseFilteredProducts.filter((product) =>
                Object.entries(activeFilters).every(([key, values]) => {
                    return !(key in product) || !product[key]
                        ? false
                        : values.length
                        ? values.includes(product[key]) ||
                          values.includes(product[key].toLowerCase())
                        : true;
                })
            );
        else return baseFilteredProducts;
    }, [baseFilteredProducts, activeFilters]);

    useEffect(() => {
        const fetchData = async () => {
            //  setLoading(true);
            try {
                const promises = collections.map(fetchDataForCollection);
                const results = await Promise.all(promises);
                const newData = collections.reduce(
                    (acc, collectionName, index) => {
                        acc[collectionName] = results[index];
                        return acc;
                    },
                    {}
                );
                setData((prevData) => ({
                    ...prevData,
                    ...newData,
                }));
            } catch (error) {
                setError(error);
            }
        };
        const delayFunctions = window.requestIdleCallback || window.setTimeout;

        delayFunctions(() => fetchData());
    }, [collections, fetchDataForCollection]);

    // Step 2: Initializing Filters from URL
    useEffect(() => {
        if (Object.keys(data).length === 1) {
            console.log('Waiting for data');
            return;
        }
        const searchParams = new URLSearchParams(location.search);
        const newActiveFilters = {};

        // Create a mapping from queryKey to key
        const queryKeyToKey = productFilters.reduce((acc, filter) => {
            acc[filter.queryKey] = filter.key;
            return acc;
        }, {});
        const queryKeyToCollection = productFilters.reduce((acc, filter) => {
            acc[filter.queryKey] = filter.collection;
            return acc;
        }, {});
        searchParams.forEach((value, queryKey) => {
            if (queryKey in queryKeyToKey) {
                const key = queryKeyToKey[queryKey];
                console.info('getting filters from query', {
                    queryKey,
                    key,
                    value,
                    newActivation: value
                        .split(',')
                        .map((slug) =>
                            getIdBySlug(slug, queryKeyToCollection[queryKey])
                        ),
                });
                newActiveFilters[key] = value
                    .split(',')
                    .map((slug) =>
                        getIdBySlug(slug, queryKeyToCollection[queryKey])
                    );
            }
        });

        setActiveFilters(newActiveFilters);
        setFiltersInitialized(true);
    }, [location.search, getIdBySlug, data.products]);
    useEffect(() => {
        if (!filtersInitialized || Object.keys(data).length === 1) {
            console.log('Waiting for data');
            return;
        }
        const currentSearchParams = new URLSearchParams(location.search);
        const newSearchParams = new URLSearchParams();

        if (currentSearchParams.has('page')) {
            newSearchParams.set('page', currentSearchParams.get('page'));
        }
        if (currentSearchParams.has('q')) {
            newSearchParams.set('q', currentSearchParams.get('q'));
        }

        const keyToQueryKey = productFilters.reduce((acc, filter) => {
            acc[filter.key] = filter.queryKey;
            return acc;
        }, {});

        const queryKeyToCollection = productFilters.reduce((acc, filter) => {
            acc[filter.queryKey] = filter.collection;
            return acc;
        }, {});

        Object.entries(activeFilters).forEach(([key, values]) => {
            console.info('Adding active filters to query:', { key, values });
            if (key in keyToQueryKey && values.length > 0) {
                const queryKey = keyToQueryKey[key];
                newSearchParams.set(
                    queryKey,
                    values
                        .map((id) =>
                            getSlugById(id, queryKeyToCollection[queryKey])
                        )
                        .join(',')
                );
            }
        });

        const newSearchString = newSearchParams.toString();
        if (currentSearchParams.toString() !== newSearchString) {
            navigate(
                {
                    pathname: location.pathname,
                    search: `?${newSearchString}`,
                },
                { replace: true }
            );
        }
    }, [
        activeFilters,
        navigate,
        location.pathname,
        departmentSlug,
        getSlugById,
        loading,
        data.products,
    ]);

    const getNameById = (id, dataType) => {
        const collectionData = data[dataType] || [];
        const found = collectionData.find((item) => item.id === id);
        return found ? found.name : undefined;
    };

    const getFilteredProducts = () => {
        // Now, this simply returns the state of filteredProducts
        return filteredProducts;
    };
    const getCountOfValuesByField = (fieldName) => {
        const baseValues = new Set();
        const countMap = {};

        // Step 1: Identify all unique field values from baseFilteredProducts
        baseFilteredProducts.forEach((doc) => {
            const value = doc[fieldName];
            if (Array.isArray(value)) {
                value.forEach((item) => baseValues.add(item));
            } else if (value) {
                baseValues.add(value);
            }
        });

        // Initialize countMap with all base values set to 0
        baseValues.forEach((value) => {
            countMap[value] = 0;
        });

        // Step 2: Count occurrences in filteredProducts
        filteredProducts.forEach((doc) => {
            const value = doc[fieldName];
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    if (baseValues.has(item)) {
                        countMap[item]++;
                    }
                });
            } else if (value && baseValues.has(value)) {
                countMap[value]++;
            }
        });

        return countMap;
    };

    const addFilter = (category, value) => {
        setActiveFilters((prev) => ({
            ...prev,
            [category]: value ? [...(prev[category] || []), value] : [],
        }));
    };

    const removeFilter = (category, value) => {
        setActiveFilters((prev) => ({
            ...prev,
            [category]: prev[category].filter((v) => v !== value),
        }));
    };

    const clearFilters = () => {
        setActiveFilters({});
        // Optionally, reset filteredProducts to all products if needed
        // setFilteredProducts(data.products);
    };

    return (
        <DataContext.Provider
            value={{
                data,
                loading,
                error,
                searchIndex,
                activeFilters,
                filteredProducts,
                productFilters,
                baseFilteredProducts,
                addFilter,
                getNameById,
                getSlugById,
                removeFilter,
                clearFilters,
                getProductById,
                getVendorById,
                getVendorBySlug,
                setDepartmentSlug,
                getFilteredProducts,
                getCountOfValuesByField,
                setBaseFilteredProducts,
                getCategoryDepartmentName,
                fetchProductsFromFirestore,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
