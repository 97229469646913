import React, { useContext } from 'react';
import { BurgerButton } from './icons';
import { MenuContext } from '../hooks/MenuContext';
import { Link } from 'react-router-dom';
export function NavBar({ name }) {
    const { toggleMenu } = useContext(MenuContext);
    return (
        <nav className={`${name}`}>
            <BurgerButton onClick={toggleMenu} />
            <ul>
                <li className="main">
                    <Link
                        onClick={toggleMenu}
                        title="Women"
                        to="/collections/women"
                    >
                        Women
                    </Link>
                </li>
                <li className="main" title="Men">
                    <Link onClick={toggleMenu} to="/collections/men">
                        Men
                    </Link>
                </li>
                <li className="main" title="Kids">
                    <Link onClick={toggleMenu} to="/collections/kids">
                        Kids
                    </Link>
                </li>
                <li className="main">
                    <Link
                        title="Available Brands"
                        onClick={toggleMenu}
                        to="/brands"
                    >
                        Available Brands
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={toggleMenu}
                        title="Our Story"
                        to="/about-us/our-story"
                    >
                        Our Story
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={toggleMenu}
                        title="Love Notes"
                        to="/about-us/customer-feedback"
                    >
                        Love Notes
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={toggleMenu}
                        title="Frequently Asked Questions"
                        to="/about-us/faq"
                    >
                        FAQ
                    </Link>
                </li>
                <li>
                    <Link onClick={toggleMenu} to="/about-us/contact">
                        Contact
                    </Link>
                </li>

                <li className="social">
                    <ul>
                        <li>
                            <a
                                href="https://poshmark.ca/closet/thrfts"
                                target="_blank"
                            >
                                <img
                                    src="/images/pm.png"
                                    height={21}
                                    width={18}
                                    alt="Poshmark"
                                />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com" target="_blank">
                                <img
                                    src="/images/fb.png"
                                    height={24}
                                    width={24}
                                    alt="Facebook"
                                />
                            </a>
                        </li>
                        <li className="social">
                            <a
                                href="https://www.instagram.com/thrfts.ca"
                                target="_blank"
                            >
                                <img
                                    src="/images/ig.png"
                                    height={24}
                                    width={24}
                                    alt="Instagram"
                                />
                            </a>
                        </li>
                        <li className="social">
                            <a href="https://www.linkedin.com/in/angela-macalpine-45a50623/">
                                <img
                                    src="/images/li.png"
                                    height={24}
                                    width={24}
                                    alt="LinkedIn"
                                />
                            </a>
                        </li>
                        <li className="social">
                            <a
                                href="https://www.youtube.com/channel/UCECNCB7v_F2yZIPCDBoMyUw"
                                target="_blank"
                            >
                                <img
                                    src="/images/yt.png"
                                    height={24}
                                    width={24}
                                    alt="YouTube"
                                />
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}
